<template>
    <div v-if="visible" class="popup-overlay" @click="close">
      <div class="popup-content" @click.stop>
        <div v-if="data"> {{ data }} </div>
        <div> Do you want to create an account with this number?</div>
        <button class="yes-button" @click="confirmYes">Yes</button>
        <button class="no-button" @click="confirmNo">No</button>
      </div>
    </div>
</template>
  
  <script>
  export default {
    props: {
      visible: {
        type: Boolean,
        required: true
      },
      data: {
        type: String,
        default: ''
      }
    },
    methods: {
      close() {
        this.$emit('close');
      },
      confirmYes() {
        this.$emit('confirm', true);
        this.close();
      },
      confirmNo() {
        this.$emit('confirm', false);
        this.close();
      }
    }
  };
  </script>
  
  <style>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  .no-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  .yes-button {
    background-color: rgb(6, 125, 35);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  </style>
  