<template>
    <div class="home-container">
      <div class="card">
        <h1>Product Registration</h1>
        <p>
            Saved Successfully
        </p>
        <p>
            Accumulated point this time
        </p>
        <p>
            {{accumulated_point}}
        </p>
        <p>
            Total accumulated point
        </p>
        <p>
            {{ total_accumulated_point}}
        </p>
        <ul>
            <li v-for="(purchase, index) in purchases" :key="index">
            {{ purchase }}
            </li>
        </ul>
        <button type="submit" @click="navigateTo('home')">Back</button>
      </div>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                accumulated_point: this.$route.query.accumulated_point,
                total_accumulated_point: this.$route.query.total_accumulated_point,
            };
        },
        methods: {
            navigateTo(page){
                this.$router.push({ path: `/${page}` });
            }
        },
    };
</script>

<style scoped>
    .home-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #f5f5f5;
    }

    .card {
      background: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
    }
    button {
      display: block;
      width: 100%;
      padding: 10px;
      margin-top: 10px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: white;
      font-size: 16px;
      cursor: pointer;
    }

    button:hover {
      background-color: #0056b3;
    }
</style>