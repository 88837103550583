<template>
    <div v-if="!isShowingErrorPage" class="form-container">
        <div v-if="loading">
            <div class="spinner-container">
                <div class="spinner"></div>
            </div>
            <div>Verifying product, please wait ... </div>
        </div>
    </div>
    <div v-if="isShowingErrorPage" class="page-container">
        <div class="content" :style="contentBackgroundStyle">
            <div class="scrollable-content">
            </div>
        </div>
    </div>
</template>


<script>
import apiClient from '../services/api.js';

export default {
    name: 'ProductRegistration02',
    data() {
        return {
            isShowingErrorPage: false,
            loading: false,
            content_page: false,
        };
    },
    async mounted() {
        const qrCode = this.$route.params.qrCode || '';
        this.loading = true;
        try {
            const response = await apiClient.get('purchase', {
            params: {
                qr_code: qrCode,
            }
            }); 

            // wait for some second
            await this.delay(process.env.VUE_APP_DELAY_TIME);

            // Go to verify page
            this.navigateToVerify('verify-page', response.data.serial_no, response.data.number_of_scan, qrCode);
        } catch(error){
            await this.delay(process.env.VUE_APP_DELAY_TIME);

            this.isShowingErrorPage = true;
            if (error.response.data.unknown_product){
                this.content_page = require('@/assets/error_background01.png');
            }
            else {
                this.content_page = require('@/assets/error_background02.png');
            }
            
        } finally {
            this.loading = false;
        }
    },
    methods: {
        navigateToVerify(page, serial_no, number_of_scan, qrCode) {
            window.location.replace(`${process.env.VUE_APP_SECOND_DOMAIN}/${page}?qr_code=${qrCode}&serial_no=${serial_no}&number_of_scan=${number_of_scan}`);
        },
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    },
    computed: {
        contentBackgroundStyle() {
        return {
            background: `url(${this.content_page}) no-repeat center center fixed`,
            'background-size': 'cover'
        };
        }
    }
};
</script>

<style scoped>
    .form-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #f5f5f5;
    }
    .page-container {
        height: 100vh;
        overflow: hidden;
        position: relative;
    }
    .card {
      background: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
      width: 300px;
    }

    .form-group {
      margin-bottom: 15px;
      text-align: left;
    }

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    input {
      width: 100%;
      padding: 8px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    button {
      display: block;
      width: 100%;
      padding: 10px;
      margin-top: 10px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: white;
      font-size: 16px;
      cursor: pointer;
    }

    button:hover {
      background-color: #0056b3;
    }

    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .spinner {
        border: 3px solid #f3f3f3;
        border-top: 3px solid #832ecd; 
        border-left: 3px solid #832ecd;
        border-right: 3px solid #832ecd;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .content {
        height: 100%;
        overflow-y: auto;
    }
    .scrollable-content {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center; 
        padding: 16px;
    }
    .content::-webkit-scrollbar {
        width: 10px;
    }
    .content::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
    }
    .content::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.7);
    }
</style>
