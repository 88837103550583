<template>
    <div class="form-container" v-if="loading">
        <div>
            <div class="spinner-container">
                <div class="spinner"></div>
            </div>
            <div>Verifying product, please wait ... </div>
        </div>
    </div>
    <div v-if="!loading">
        <div class="page-container">
            <div class="content" v-if="showContent">
                <div class="scrollable-content">
                    <Card>
                        <img src="@/assets/first_image.png" class="card-image"/>
                    </Card>
                    
                    <Card>
                        <div class="text-container">
                            <img src="@/assets/data_content.png" class="card-image"/>
                            <div v-if="serial_no" class="serial-no-text">
                                {{serial_no}} 
                            </div>
                            <div v-if="number_of_scan" class="scanning-number-text">
                                {{ number_of_scan }}
                            </div>
                        </div>
                    </Card>
                    
                    <Card>
                        <img src="@/assets/second_image.png" class="card-image" />
                    </Card>

                    <Card>
                        <img src="@/assets/third_image.png" class="card-image" />
                    </Card>

                    <Card>
                        <h3>!!! โปรดระวังของปลอม !!!</h3>
                        <p>ของแท้จะสแกนได้ 5 ครั้งเท่านัน</p>
                        <p>เวลาทีสแกนจะขึนหมายเลขรหัสและจํานวนครังทีสแกน</p>
                    </Card>

                    <Card>
                        <p>เวลาสแกนหมายเลขรหัสที่โชว์จะต องตรงกับหมายเลขที่อยู่บนสติกเกอร์ เท่านั้น ถ้าสแกนแล้วหมายเลขไม่ตรง จํานวนครั้งไม่มีการเปลี่ยนแปลงให้ระวังของปลอม  </p>
                    </Card>

                    <Card>
                        <p>หากต้องการสแกนผ่านแอปพลิเคชัน ให้โหลดแอปตามวิธีด้านล่างนี 👇</p>
                    </Card>

                    <Card>
                        <img src="@/assets/fourth_image.png" alt="Description of the image" class="card-image" />
                    </Card>

                    <Card>
                        <img src="@/assets/fifth_image.png" alt="Description of the image" class="card-image" />
                    </Card>

                    <Card>
                        <p>✅ เอกสารใบกํากับยา ✅</p>
                    </Card>

                    <Card>
                        <img src="@/assets/sixth_image.png" alt="Description of the image" class="card-image" />
                    </Card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apiClient from '../services/api.js';
import Card from './Card.vue';

export default {
    name: 'ProductRegistration',
    components: {
        Card
    },
    data() {
        return {
            popup_data: null,
            popup_text: null,
            loading: true,
            qr_code: this.$route.query.qr_code,
            serial_no: this.$route.query.serial_no,
            number_of_scan: this.$route.query.number_of_scan,
            showContent: false,
            data: null,
        };
    },
    async mounted(){
        try {
            await this.delay(process.env.VUE_APP_DELAY_TIME);
        } finally {
            this.loading = false;
            try {        
                console.log("hello");
                const current_location = await this.getLocationAndRedirect();
                apiClient.post('location', {
                    qr_code: this.qr_code,
                    latitude: current_location.latitude,
                    longitude: current_location.longitude,
                })
                .catch(error =>{
                    if (error.response){
                        this.data = error.response.data.msg;
                    }
                });

            } catch (error) {
                console.error("Error:", error);
            } finally {
                this.showContent = true;
            }
        }
    },
    methods: {
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        getLocationAndRedirect() {
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        position => {
                            const latitude = position.coords.latitude;
                            const longitude = position.coords.longitude;

                            resolve({ latitude, longitude });
                        },
                        error => {
                            this.handleLocationError(error);
                            reject(error);
                        }
                    );
                } else {
                    const error = { message: "Geolocation is not supported by this browser." };
                    this.handleLocationError(error);
                    reject(error);
                }
            });
        },
        handleLocationError(error) {
            // This function is used for debugging the error
            console.error("Error getting location: ", error.message);

        }
    },
};
</script>

<style scoped>
    .form-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #ffffff;
    }
    .card {
      background: rgb(255, 255, 255);
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
      width: 350px;
    }
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .spinner {
        border: 3px solid #f3f3f3;
        border-top: 3px solid #832ecd;
        border-left: 3px solid #832ecd;
        border-right: 3px solid #832ecd;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .page-container {
        height: 100vh;
        overflow: hidden;
        position: relative;
        background: url('~@/assets/background-image.png') no-repeat center center fixed;
        background-size: cover;
    }
    .content {
        height: 100%;
        overflow-y: auto;
    }
    .scrollable-content {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center; 
        padding: 16px;
    }
    .content::-webkit-scrollbar {
        width: 10px;
    }
    .content::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
    }
    .content::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.7);
    }
    .card-image {
        width: 100%;
        height: auto;
        display: block; 
        border-radius: 0; 
        margin: 0; 
    }
    .text-container {
        position: relative; /* Container for image and text */
    }
    .serial-no-text {
        position: absolute;
        top: 146px;
        left: 12px;
        color: rgb(0, 0, 0);
        background-color: rgb(255, 255, 255);
        font-weight: bold;
    }
    .scanning-number-text {
        position: absolute;
        top: 186px;
        left: 12px;
        color: rgb(0, 0, 0);
        background-color: rgb(255, 255, 255);
        font-weight: bold;
    }
</style>