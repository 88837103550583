<template>
    <div class="card">
        <div class="card-body">
            <slot></slot>
            {{ content }}
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      content: {
        type: String,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  .card {
    border: none; 
    padding: 0; 
    margin-bottom: 16px;
    max-width: 700px; 
    width: 100%;
    box-sizing: border-box; 
    position: relative;
  }
  .card-header {
    font-weight: bold;
    margin-bottom: 8px;
  }
  .card-body {
    font-size: 14px;
  }
  </style>
  