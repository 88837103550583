<template>
    <div class="home-container">
        <div class="card">
          <h1>Purchase History</h1>
          <ul>
            <li v-for="(purchase, index) in purchases" :key="index">
              {{ purchase }}
            </li>
          </ul>
          <button type="submit" @click="navigateTo('home')">Back</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PurchaseHistory',
        data() {
            return {
                purchases: [
                  // Sample data; replace with actual purchase history
                  'Purchase 1: Item A - $10',
                  'Purchase 2: Item B - $20',
                ],
            };
        },
        methods: {
            navigateTo(page){
                this.$router.push({ path: `/${page}` });
            }
        },
    };
</script>

<style scoped>
    .home-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #f5f5f5;
    }

    .card {
      background: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
    }
    button {
      display: block;
      width: 100%;
      padding: 10px;
      margin-top: 10px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: white;
      font-size: 16px;
      cursor: pointer;
    }

    button:hover {
      background-color: #0056b3;
    }
</style>
