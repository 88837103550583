import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_DOMAIN + '/product_qr_tracking/', // Replace with your Django server URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export default apiClient;
